import React from 'react';
import styled from 'styled-components';

import { ContactDetails, ContactHero, ContactForm } from 'components/Contact';
import { SEO } from 'components/UI';
import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import contact from 'data/contact.json';
import seo from 'data/seo.json';
import { MediaQueries, Sizes } from 'environment';

export default function Contact() {
	return (
		<Container>
			<SEO
				title={seo.contactTitle}
				description={seo.contactDescription}
				ogTitle={seo.contactOgTitle}
				ogDescription={seo.contactOgDescription}
				ogImage={seo.contactOgImage}
			/>
			<ContactHero />
			<Content>
				<ContactForm title={contact.form.title} />
				<ContactDetails />
			</Content>
		</Container>
	);
}

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export const Content = styled.div`
	display: flex;
	width: 100%;
	margin: 12rem 0;

	@media ${MediaQueries.phone} {
		flex-direction: column;
		margin: 6rem 0;
		padding: 0 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
		flex-direction: column;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding: 0 2rem;
	}
`;
